<template>
  <div class="migration-destination-details-page">
    <div class="readable_content">
      <h4>The Chinese Community in {{ mainPlaceName }}</h4>
      <div class="sub-section wiki-article" v-if="placeState.wiki_article" v-prerender-if="'wiki_article.content'">
        <div v-html="placeState.wiki_article.content"></div>
        <div class="read-more-link" v-if="!isTemplate">
          <router-link :to="wikiArticleRoute" class="bold">Read more on the Genealogy Wiki</router-link>
        </div>
      </div>
      <div
        class="sub-section"
        v-if="isTemplate || (placeState.migration_meta.description && !placeState.wiki_article)"
        v-prerender-if="'migration_meta.description'"
        v-html="placeState.migration_meta.description"
      ></div>
      <div
        class="sub-section"
        v-if="placeState.migration_meta.est_population"
        v-prerender-if="'migration_meta.est_population'"
      >
        <span><strong>Estimated Population: </strong></span>
        <span>{{ placeState.migration_meta.est_population.toLocaleString('en') }}</span>
      </div>
      <section-dialects
        v-if="placeState.dialects_list && placeState.dialects_list.length"
        v-prerender-if="'dialects_list'"
        :dialects="placeState.dialects_list"
        :is-template="isTemplate"
        class="sub-section"
      >
      </section-dialects>
      <section-wiki-articles
        class="sub-section"
        :place-id="placeState.id"
        :exclude-slug="placeState.wiki_article && placeState.wiki_article.slug"
        v-if="!isTemplate"
      ></section-wiki-articles>
      <section-search-place-records
        v-if="!isTemplate && searchRecordsMeta && searchRecordsMeta.total_count"
        class="section"
        :place-id="placeState.id"
        :place-name="mainPlaceName"
      ></section-search-place-records>

      <cta-block-compare-plans class="section"></cta-block-compare-plans>

      <section-google-map
        v-if="showMap"
        class="section"
        :address="placeState.full_address_en"
        :name="placeState.name"
        :level="placeState.level"
        :coordinates="placeState.location"
        @set-marker="onMarkerSet"
      ></section-google-map>
      <div class="section" v-else></div>

      <section-address
        v-if="placeState.level !== 0"
        :items="placeState.ancestors"
        :full-address-en="placeState.full_address_en"
      ></section-address>

      <section-records
        v-if="showSectionRecords"
        :pinyin="mainPlaceName"
        :place-id="placeState.id"
        :meta="searchRecordsMeta"
        class="section"
      ></section-records>

      <ancestral-locations-section
        class="section"
        :pinyin="mainPlaceName"
        :place-id="placeState.id"
        :description="placeState.migration_meta.origin_description"
        :description-prerender-name="'migration_meta.origin_description'"
        :is-template="isTemplate"
      ></ancestral-locations-section>
      <popular-surnames-section class="section" :pinyin="mainPlaceName"></popular-surnames-section>
    </div>
  </div>
</template>

<script>
import AncestralLocationsSection from '@common/pages/placeDetails/AncestralLocationsSection';
import PopularSurnamesSection from '@common/pages/placeDetails/PopularSurnamesSection';
import SectionGoogleMap from '@common/pages/placeDetails/SectionGoogleMap';
import SectionRecords from '@common/pages/placeDetails/SectionRecords.vue';
import SectionWikiArticles from '@common/pages/placeDetails/SectionWikiArticles';
import SectionAddress from '@common/pages/placeDetails/migration/SectionAddress';
import SectionSearchPlaceRecords from '@common/pages/placeDetails/migration/SectionSearchPlaceRecords';
import SectionDialects from '@common/pages/placeDetails/village/SectionDialects.vue';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

import migrationDestinationData from '../prerender/placeData';
import placeSearchMeta from '../prerender/placeSearchMeta';
import spellingsListData from '../prerender/spellingsListData';

export default {
  metaInfo() {
    let name = this.placeState ? this.placeState.pinyin : '';
    name = this.placeState && this.placeState.definitive_article ? `the ${name}` : name;
    return {
      title: `The Chinese in ${name} Facts and Origins`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Discover the history of Chinese immigration to ${name}, and where the Chinese in ${name} come from. Search records of Chinese in ${name}. Browse popular surnames of Chinese in ${name}.`,
        },
      ],
    };
  },
  created() {
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setPlaceState', migrationDestinationData);
      this.$store.commit('setSpellingSearchState', spellingsListData);
      this.$store.commit('setPlaceSearchMetaState', placeSearchMeta);
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setPlaceState', window.__PRERENDERED_STATE__.place);
      this.$store.commit('setSpellingSearchState', window.__PRERENDERED_STATE__.spellings);
      this.$store.commit('setPlaceSearchMetaState', window.__PRERENDERED_STATE__.place_search_meta);
      window.__PRERENDERED_STATE__ = null;
      return;
    }
    // set showMap true in 1 second
    setTimeout(() => {
      this.showMap = true;
    }, 1000);
    this.init();
  },
  props: {
    searchRecordsMeta: Object,
  },
  data() {
    return {
      isTemplate: false,
      showMap: false,
    };
  },
  computed: {
    ...mapGetters(['placeState']),
    showSectionRecords() {
      return !this.isTemplate && this.searchRecordsMeta.facets && !isEmpty(this.searchRecordsMeta.facets.category_id);
    },
    wikiArticleRoute() {
      if (this.placeState.wiki_article) {
        return {name: 'wiki-article', params: {slug: this.placeState.wiki_article.slug}};
      }
    },
    mainPlaceName() {
      return this.placeState.definitive_article
        ? `the ${this.placeState.display_text_short}`
        : this.placeState.display_text_short;
    },
  },
  methods: {
    init() {
      this.$store.dispatch('searchSpellingsAction', {place_id: this.$route.params.placeId, limit: 28});
      this.$store.dispatch('searchAncestralPlacesAction', {migration_place_id: this.$route.params.placeId});
    },
    onMarkerSet({location}) {
      if (isEmpty(this.placeState.location)) {
        this.$store.commit('setPlaceLocationState', location);
        this.$store.dispatch('savePlaceLocationAction', {id: this.placeState.id, location});
      }
    },
  },
  components: {
    SectionSearchPlaceRecords,
    SectionDialects,
    SectionRecords,
    CtaBlockComparePlans,
    AncestralLocationsSection,
    PopularSurnamesSection,
    SectionAddress,
    SectionGoogleMap,
    SectionWikiArticles,
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin-top: 50px;
}
.sub-section {
  margin-top: 18px;
}
.wiki-article {
  padding-bottom: 16px;
  border-bottom: 1px solid $divider-line-color;
}
.read-more-link {
  margin-top: 8px;
}
</style>
